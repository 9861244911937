import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";

import Meta from "vue-meta";
import auth from "@/router/auth";

Vue.use(VueRouter);

Vue.use(Meta, {
  keyName: "metaInfo", // the component option name that vue-meta looks for meta info on.
  attribute: "data-vue-meta", // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: "data-vue-meta-server-rendered", // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: "vmid", // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

const routes = [
  {
    path: "/Dashboard",
    name: "home-index",
    component: Home,
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/login",
    name: "account-login",
    component: () => import("@/views/account/Login.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/register",
    name: "account-register",
    component: () => import("@/views/account/Register.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Profile
  {
    path: "/profile",
    name: "account-profile",
    component: () => import("@/views/account/Profile.vue"),
    beforeEnter: auth.requireAuth,
  },
  // thanh toán
  {
    path: "/transaction-list",
    name: "transaction-list",
    component: () => import("@/views/transaction/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // lịch sửa giao dịch 
  {
    path: "/history-transaction",
    name: "history-transaction",
    component: () => import("@/views/transaction/History.vue"),
    beforeEnter: auth.requireAuth,
  },
  // mua gói
  {
    path: "/pack-list",
    name: "pack-list",
    component: () => import("@/views/pack/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // trung tâm api
  {
    path: "/api-center",
    name: "api-center",
    component: () => import("@/views/api/ApiList.vue"),
    beforeEnter: auth.requireAuth,
  },
  // ủy quyền
  {
    path: "/authority/:id",
    name: "authority",
    component: () => import("@/views/application/ListAuth.vue"),
    beforeEnter: auth.requireAuth,
  },
  // phân tích
  {
    path: "/analysis/:id",
    name: "analysis",
    component: () => import("@/views/application/Analysis.vue"),
    beforeEnter: auth.requireAuth,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
