<template>
  <div
    class="component-header-top d-flex justify-content-between align-items-center"
  >
    <div class="seach-global">
      <img src="@/static/icon/header-top/logo.svg" alt="">
    </div>

  <div class="d-flex align-items-center">
    <!-- menu  -->
    <div class="list-menu d-flex flex-row">
      <div v-for="menu in list" :key="menu.text">
        <div
          class="menu-item"

        >
          <router-link
            class="spanLink"
            :to="{ name: menu.name, params: {} }"
            :class="menu.url == path ? 'active-link-head' : ''"
          >
            <span >{{ menu.text }}</span>
          </router-link>

          <!-- <span>{{ menu.text }}</span> -->
        </div>
      </div>
    </div>

    <!-- <div class="d-flex align-items-center menu-item">
      <span>số dư: <span style="color: #f1c330; font-weight: 700;">{{ accountInfo.total_money | vnd }}</span></span>
      <img class="pl-1" src="@/static/icon/header-top/dollar.svg" alt="" />
    </div> -->

    <!-- <div class="noti px-2">
      <img src="@/static/icon/header-top/save-2.svg" alt="" />
    </div> -->

    <div class="noti px-2">
      <img src="@/static/icon/header-top/noti.svg" alt="" />
    </div>

    <div
      class="dropdown-account"
      ref="dropdownProfile"
      @click="showMenuTop = !showMenuTop"
    >
      <img
        v-if="accountInfo.avatar_path"
        :src="accountInfo.avatar_path"
        class="avatar"
      />
      <span v-else><img src="@/static/icon/header-top/account.svg" /></span>
      <span class="name">
        {{ accountInfo.name }}
        <img class="down" src="@/static/icon/header-top/down.svg" />
      </span>
      <ul v-if="showMenuTop">
        <router-link
          :to="{ name: 'account-profile', params: {} }"
          @click.native.stop="showMenuTop = false"
        >
          <img src="@/static/icon/header-top/user.svg" alt="">
          <span>Thông tin tài khoản</span>
        </router-link>
        <!-- <span>Đổi mật khẩu</span> -->
        <a @click="dialogChangePass = true">
          <img src="@/static/icon/header-top/lock.svg" alt="" />
          <span>Đổi mật khẩu</span>
        </a>
        <router-link
          :to="{ name: 'history-transaction', params: {} }"
          @click.native.stop="showMenuTop = false"
        >
          <img src="@/static/icon/header-top/clock.svg" alt="" />
          <span>Lịch sử giao dịch</span>
        </router-link>
        <a class="divider"></a>
        <a @click.stop.stop="confirmLogout">
          <img src="@/static/icon/header-top/logout.svg" alt="" />
          <span>Thoát tài khoản</span>
        </a>
      </ul>
    </div>


    <div class="dialog-common">
      <el-dialog
        title="Đổi mật khẩu"
        top="50px"
        width="30%"
        :close-on-click-modal="false"
        :visible.sync="dialogChangePass"
      >
        <div class="dialog-body">
          <div class="d-flex flex-column">
            <span class="txt-edit">Mật khẩu cũ</span>
            <el-input
              v-model="userInfo.old_password"
              class="input-common"
            ></el-input>
            <span class="txt-edit">Mật khẩu mới</span>
            <el-input
              v-model="userInfo.password"
              class="input-common"
              show-password
            ></el-input>
            <span class="txt-edit">Nhập lại mật khẩu mới</span>
            <el-input
              v-model="userInfo.password_confirmation"
              class="input-common"
              show-password
            ></el-input>
          </div>
        </div>

        <div class="footer-dialog">
          <el-button @click="dialogChangePass = false">Hủy bỏ</el-button>
          <el-button
            :disable="!canSubmit"
            :loading="!canSubmit"
            @click="changePass()"
            class="editDone">Xác nhận</el-button>
        </div>

      </el-dialog>

    </div>

  </div>
  </div>
</template>

<script>
import axios from "axios";
import ls from "local-storage";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "LayoutHeaderTop",
  data() {
    return {
      seach: "",
      showMenuTop: false,
      dialogChangePass: false,
      canSubmit: true,
      userInfo: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      list: [
        {
          text: "Trung tâm API",
          url: "api-center",
          name: "api-center",
          rootPermission: [],
        },
        {
          text: "Bảng giá",
          url: "pack-list",
          name: "pack-list",
          rootPermission: [],
        },
        // {
        //   text: "Tổ chức",
        //   url: "123",
        //   name: "",
        //   rootPermission: [],
        // },
        // {
        //   text: "Ứng dụng",
        //   url: "123",
        //   name: "",
        //   rootPermission: [],
        // },
        // {
        //   text: "APIs của tôi",
        //   url: "123",
        //   name: "",
        //   rootPermission: [],
        // },
      ],
    };
  },
  computed: {
    ...mapGetters("account", ["accountInfo"]),
    ...mapGetters(["path", "full_path"]),

  },
  mounted() {
    document.addEventListener("click", this.documentClick);
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
  methods: {

    changePass() {
      this.canSubmit = false;
      axios.post("account/change-password", this.userInfo).then((response) => {
        this.canSubmit = true;
        switch (response.code) {
          case 200:
            this.$notify({
              title: "Thành công",
              message: "Đổi mật khẩu thành công",
              type: "success",
            });
            this.dialogChangePass = false
            this.getAccountInfo();
            break;
          case 400:
            this.$notify({
              title: "có lỗi xảy ra",
              message: response.errors[0],
              type: "warning",
            });
            break;
            case 500:
              this.$notify({
              title: "có lỗi xảy ra",
              message: response.message,
              type: "warning",
              });
            break;
            case 422:
            this.$notify({
                title: "Vui lòng kiểm tra lại thông tin",
                message: Object.values(response.data)[0],
                type: "warning",
              });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },

    documentClick(e) {
      let el = this.$refs.dropdownProfile;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.showMenuTop = false;
      }
    },
    
    ...mapActions("account", ["removeAccountInfo"], ["getAccountInfo"]),
    mouseLeave() {
      this.showMenuTop = false;
    },
    confirmLogout() {
      this.$confirm("Xác nhận thoát tài khoản", "Warning", {
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy",
        type: "warning",
      })
        .then(() => {
          ls('out', true);
          this.removeAccountInfo();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="css" scoped>
.component-header-top {
  height: 50px;
  padding: 0px 15px;
  background: #6049CD;
}
.seach-global {
  min-width: 280px;
}

.dropdown-account {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding: 0px 3px 0px 10px;
  position: relative;
}
.dropdown-account img {
  margin-right: 8px;
}
.dropdown-account .down {
  width: 10px;
  margin-right: 0px;
  margin-left: 5px;
}
.dropdown-account .name {
  color: #fff;
  font-size: 12px;
}
.dropdown-account ul {
  position: absolute;
  top: 50px;
  right: 0px;
  margin: 0px;
  width: 200px;
  background-color: #fff;
  padding: 5px 0px 5px 0px;
  z-index: 1000;
  border: 1px solid #f2f2f2;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}
.dropdown-account ul a {
  list-style-type: none;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  text-decoration: none;
  color: #2c3e50;
}
.dropdown-account ul a:hover {
  background-color: #f2f2f2;
}
.dropdown-account ul a.divider {
  height: 1px;
  background-color: #ccc;
  margin-bottom: 5px;
}
/* meun item  */
.component-header-top .menu-item {
  padding: 10px 10px 14px 10px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}
</style>
<style type="text/css">
.component-header-top input {
  border-radius: 25px;
  height: 28px;
  font-size: 12px;
  /* border: none; */
}
.component-header-top .el-input__icon {
  line-height: 28px;
}
.avatar {
  max-width: 100%;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.spanLink {
  color: #fff;
  text-decoration: none;
}
.active-link-head {
  color: #F1C430 !important;
}
.total_money_text {
  font-size: 12px;
  color: #fff;
}
</style>
