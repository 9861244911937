<template>
  <div id="app">
      <div class="is-login
      ">
        <div v-if="logged" class="headerTop">
          <header-top></header-top>
        </div>
        <div class="d-flex contenView" :class="[logged == false ? 'full-contenView' : '']">
          <div class="app-menu-left" v-if="logged" :class="[showText ? 'app-menu-left-full' : '']">
            <menu-left @toggleMenu="toggleMenuParent"></menu-left>
          </div>
          <div
            class="content-right app-content-right"
            :class="[showText ? 'app-content-right-full' : '' || logged == false ? 'full-screen' : '' | path == 'api-center' ? 'padding0' : '' ]"
          >
            <transition :name="logged ? 'slide-fade' : ''">
              <router-view />
            </transition>
          </div>
        </div>
      </div>
      <div class="not-login">
        <!-- <router-view /> -->
      </div>
    </div>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from "vuex";
import ls from "local-storage";
import MenuLeft from "@/views/layout/MenuLeft";
import HeaderTop from "@/views/layout/HeaderTop";
export default {
  name: "App",
  metaInfo () {
    return {
      title: this.title,
      titleTemplate: 'Buyder | %s',
      showText: false,
    }
  },
  components: {
    MenuLeft,
    HeaderTop,
  },
  computed: {
    ...mapGetters("account", ["logged"]),
    ...mapGetters(["path", "full_path"]),
  },
  data() {
    return {
      showText: false,
    };
  },
  mounted() {
    this.checkLoged();
    if(this.path == "") {
      this.$router.push({ name: "home-index" });
    }
  },
  methods: {
    ...mapActions("account", ["getAccountInfo"]),
    checkLoged() {
      let authen = ls.get("authen");
      if (!authen) {
        if (this.path !== "register") {
          this.$router.push({ name: "account-login" });
        }
      } else {
        if (!this.logged) {
          this.getAccountInfo();
        }
      }
    },

    toggleMenuParent(value) {
      this.showText = !value;
    },
  },
};
</script>

<style>
.headerTop {
  height: 50px;
}
.contenView {
  height: calc(100vh - 50px);
  overflow-y: scroll;
  width: 100%;
  background: #F2F3F7;

}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.full-screen {
  /* height: 100%; */
  width: 100% !important;
  padding: 0 !important;
  background: #fff !important;
}
.full-contenView {
  width: 100%;
  height: calc(100vh);
}
.content-right {
  width: calc(100% - 95px);
  background: #F2F3F7;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
}
.app-content-right-full {
  width: 85% !important;
  transition: 0.3s;
}
.app-menu-left {
  width: 64px;
  min-height: 100vh;
  background: #fff;
}
.app-menu-left-full {
  width: 15% !important;
}
.padding0 {
  padding: 0 !important;
  width: 100%;
}
</style>
