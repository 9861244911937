<template>
  <div class="menu-left">
    <div class="icon-action" @click="isCollapseData()">
      <span v-if="isCollapse == true"><i class="el-icon-s-unfold"></i></span>
      <span v-else><i class="el-icon-s-fold"></i></span>
    </div>
    <el-menu :default-active="path" class="menu-vertical" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
      <el-submenu class="avatar-left" index="">
        <template slot="title">
          <div class="d-flex align-items-center">
            <img class="image-left" v-if="accountInfo.avatar_path" :src="accountInfo.avatar_path" alt="" />
            <div class="image-left" v-else></div>
            <div class="d-flex flex-column info-acc">
              <span>Tài Khoản</span>
              <span style="font-weight: 700;">{{ this.accountInfo.name }}</span>
            </div>
          </div>
        </template>
        <el-menu-item-group>
          <el-menu-item @click="nextRoute('account-profile', '/profile')">Thông tin tài khoản</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <!-- Dashboard -->
      <router-link to="/Dashboard">
        <el-menu-item index="/Dashboard">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.96997 22H14.97C19.97 22 21.97 20 21.97 15V9C21.97 4 19.97 2 14.97 2H8.96997C3.96997 2 1.96997 4 1.96997 9V15C1.96997 20 3.96997 22 8.96997 22Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.96997 12.7001L7.96997 12.6801C8.71997 12.6801 9.55997 13.2501 9.83997 13.9501L10.98 16.8301C11.24 17.4801 11.65 17.4801 11.91 16.8301L14.2 11.0201C14.42 10.4601 14.83 10.4401 15.11 10.9701L16.15 12.9401C16.46 13.5301 17.26 14.0101 17.92 14.0101H21.98" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span slot="title">Dashboard</span>
        </el-menu-item>
      </router-link>
      <!-- LỊCH SỬ THANH TOÁN -->
      <router-link to="/transaction-list">
        <el-menu-item index="/transaction-list">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 6V18" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H15C20 2 22 4 22 9V15C22 20 20 22 15 22Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span slot="title">Lịch sử thanh toán</span>
        </el-menu-item>
      </router-link>

      <!-- ỨNG DỤNG  -->


      <!-- <router-link to="/analysis">
        <el-menu-item>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.75 12L10.58 14.83L16.25 9.17004" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span slot="title">Ứng dụng</span>
        </el-menu-item>
      </router-link> -->
      <el-menu-item @click="toShowDetail()" v-if="listPack.length != 0">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.75 12L10.58 14.83L16.25 9.17004" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span slot="title">Ứng dụng</span>
      </el-menu-item>

      <!-- /PHÂN TÍCH -->

      <el-submenu class="avatar-left" :index="'/' + item.app_name" v-for="item in listPack" :key="item.id">
        
        <template slot="title">
          <div class="d-flex flex-column" style="line-height: 25px;">
            <span class="info-acc" style="font-size: 12px !important;font-weight: 700;">{{ item.pack_group_text }}</span>
            <span class="info-acc" style="font-size: 12px !important">App: <span style="font-weight: 700;">{{ item.app_name }}</span></span>
          </div>
        </template>

        <el-menu-item-group :index="item.id">
          <router-link :to="{ name: 'analysis', params: {id: item.id} }">
            <el-menu-item :index="'/analysis' + '/' + item.id">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 22H21" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.59998 8.38H4C3.45 8.38 3 8.83 3 9.38V18C3 18.55 3.45 19 4 19H5.59998C6.14998 19 6.59998 18.55 6.59998 18V9.38C6.59998 8.83 6.14998 8.38 5.59998 8.38Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.7999 5.18994H11.2C10.65 5.18994 10.2 5.63994 10.2 6.18994V17.9999C10.2 18.5499 10.65 18.9999 11.2 18.9999H12.7999C13.3499 18.9999 13.7999 18.5499 13.7999 17.9999V6.18994C13.7999 5.63994 13.3499 5.18994 12.7999 5.18994Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20 2H18.4C17.85 2 17.4 2.45 17.4 3V18C17.4 18.55 17.85 19 18.4 19H20C20.55 19 21 18.55 21 18V3C21 2.45 20.55 2 20 2Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              <span slot="title" style="font-size: 12px;">Phân tích</span>
            </el-menu-item>
          </router-link>

          <!-- ỦY QUYỀN  -->

          <router-link :to="{ name: 'authority', params: {id: item.id} }">
            <el-menu-item :index="'/authority' + '/' + item.id">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.4899 2.23006L5.49991 4.11006C4.34991 4.54006 3.40991 5.90006 3.40991 7.12006V14.5501C3.40991 15.7301 4.18991 17.2801 5.13991 17.9901L9.43991 21.2001C10.8499 22.2601 13.1699 22.2601 14.5799 21.2001L18.8799 17.9901C19.8299 17.2801 20.6099 15.7301 20.6099 14.5501V7.12006C20.6099 5.89006 19.6699 4.53006 18.5199 4.10006L13.5299 2.23006C12.6799 1.92006 11.3199 1.92006 10.4899 2.23006Z" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.05005 11.8701L10.66 13.4801L14.96 9.18005" stroke="#5F616E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span slot="title" style="font-size: 12px;">Ủy quyền</span>
            </el-menu-item>
          </router-link>

        </el-menu-item-group>
      </el-submenu>

    </el-menu>
  </div>
</template>

<script>
import axios from "axios";

import { mapGetters } from "vuex";

export default {
  name: "HomeIndex",
  metaInfo() {
    return {
      title: "Buyder",
    };
  },
  components: {},
  data() {
    return {
      loading: true,
      isCollapse: true,
      listPack: [],
    };
  },
  computed: {
    ...mapGetters("account", ["logged", "accountInfo"]),
    ...mapGetters(["path", "full_path"]),
  },
  mounted() {
    this.fetchPack();
  },
  methods: {
    toShowDetail() {
      this.$router.push({
        name: "analysis",
        params: { id: this.listPack[0].id },
      });
    },

    isCollapseData() {
      this.isCollapse = !this.isCollapse;
      this.$emit("toggleMenu", this.isCollapse);
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    nextRoute(name, full_path) {
      if (this.full_path !== full_path) {
        this.$router.push({ name: name });
      }
    },

    fetchPack() {
      this.loading = true
      axios.get("account/get-pack").then((response) => {
        this.loading = false
        switch (response.code) {
          case 200:
            this.listPack = response.data;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
  },
  watch: {},
};
</script>

<style lang="css" scoped>
.icon-action {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.menu-vertical:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}
.icon-left {
  margin-right: 10px;
}
</style>
<style>

.menu-vertical {
  border: none !important;
}
.menu-vertical .menu-vertical {
  font-size: 12px;
}
.active {
  color: #9D62F5 !important;
}

/*****************/
/**** EL MENU ****/
/*****************/
.el-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.el-menu > a {
  text-decoration: unset !important;
}

.el-menu .router-link-active .el-menu-item,
.el-menu .router-link-active svg path {
  font-weight: 500;
  color: #9D62F5;
  stroke: #9D62F5;
}

.el-menu svg {
  margin-right: 8px;
}

/*****************/

.menu-vertical .el-menu-item:hover {
  background: #fff;
}
.menu-vertical .el-menu-item.is-active {
  background: #fff;
  color: #303133;
}
.image-left {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #9D62F5;
}
.avatar-left {
  padding: 5px 0;
  border-bottom: 1px solid #F2F3F7;
  border-top: 1px solid #F2F3F7;

  margin-bottom: 10px !important;
}
.avatar-left .el-submenu__title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 17px !important;
}
.info-acc {
  padding-left: 10px;
  font-size: 13px !important;
  /* font-weight: 500; */

}
.info-acc span {
  line-height: 25px;
}
.menu-vertical .el-menu-item {
  /* font-weight: 500; */
  font-size: 13px !important;

}
.menu-vertical .el-menu-item, .el-submenu__title {
  line-height: normal;
}
/* .avatar-left img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
} */
.el-menu-item-group__title {
  padding: 0 !important;
}
</style>
