<template>
  <div class="home-index">
    <div class="title-head-index">
      <span>Dashboard</span>
    </div>
    <div class="verify_status_error" v-if="accountInfo.verify_status == 0">
      <span>Tài khoản của quý khách chưa được <span style="color: #4A3AFF; font-weight: 700;">xác thực</span>, vui lòng cập nhật thông tin <span style="color: #4A3AFF; font-weight: 700; cursor: pointer;" @click="toProfile()">tại đây</span></span>
    </div>
    
    <div class="d-flex align-items-center justify-content-between" v-loading="loading">
      <div class="box-content d-flex flex-column">
        <span class="name-box">API Calls</span>
        <span class="info-content">Tổng API Calls (Mặc định)</span>
        <div class="number-text-box">
          <span>{{ dataStatic.total_api | vnd2 }}</span>
        </div>
      </div>

      <div class="box-content d-flex flex-column">
        <span class="name-box">Lỗi</span>
        <span class="info-content">Tỷ lệ lỗi trung bình (Mặc định)</span>
        <div class="number-text-box">
          <span>0%</span>
        </div>
      </div>

      <div class="box-content d-flex flex-column">
        <span class="name-box">Độ trễ</span>
        <span class="info-content">Độ trễ trung bình (Mặc định)</span>
        <div class="number-text-box" style="color: #f28c20 !important;">
          <span >{{ dataStatic.total_time_late_tb | vnd2 }} ms</span>
        </div>
      </div>

    </div>

    <div class="box-white" style="margin: 16px 0;">
      <el-select
        v-model="filter.pack_id"
        class="input-common-select"
        clearable
        placeholder="Gói của bạn">
        <el-option
          v-for="item in listPack"
          :key="item.id"
          :label="item.app_name"
          :value="item.id">
        </el-option>
      </el-select>
    </div>

    <div class="box-white">
      <div class="filter-box d-flex align-items-center">
        <!-- <div class="d-flex flex-column">
          <span>Thống kê</span>
          <el-select
            v-model="input"
            placeholder="Tổng API calls (Mặc định)"
            class="filter-select-common"
            size="mini"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <div class="d-flex flex-column pl-4">
          <span>Phạm vi thời gian</span>
          <el-select
            v-model="filter.timeInput"
            placeholder="thời gian"
            class="filter-select-common"
            size="mini"
            clearable
          >
            <el-option
              v-for="item in timerChart"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <!-- <div class="d-flex flex-column pl-4">
          <span>Loại</span>
          <el-select
            v-model="input"
            placeholder="Loại"
            class="filter-select-common"
            size="mini"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <!-- <div class="d-flex flex-column pl-4">
          <span>Khu vực giờ</span>
          <el-select
            v-model="input"
            placeholder="Khu vực giờ"
            class="filter-select-common"
            size="mini"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div> -->
      </div>
    </div>

    <div class="box-white mt-3 boxChart">
      <highcharts class="chart" :options="chartData"></highcharts>
    </div>

    <div class="d-flex box-content-bottom py-3">
      <div class="box-bt mr-2">
        <div class="py-2">
          <span class="name-box">Thanh toán gần đây</span>
        </div>
        <div class="content-bt py-2" v-for="item in listHistory" :key="item.key">
          <span class="name-box">{{ item.money | vnd }}</span>
          <span v-if="item.content" class="pl-4 info-content">{{ item.content }} lúc {{ item.time }}</span>
        </div>
      </div>
      <div class="box-bt">
        <div class="py-2">
          <span class="name-box pb-2">Gói được sử dụng nhiều nhất</span>
        </div>
        <div class="py-2 d-flex" style="width: 100%;" v-for="item in listTotal" :key="item.id">
          <span style="width: 20%;" class="name-box">{{ item.name }}</span>
          <div style="width: 80%;">
            <el-progress color="#6049CD" :percentage="(item.total/item.totalAll*100 | percent)"></el-progress>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Chart} from 'highcharts-vue'

import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "HomeIndex",
  metaInfo() {
    return {
      title: "Buyder",
    };
  },
  components: {
    highcharts: Chart
  },
  data() {
    return {
      loading: true,
      value: [],
      input: "",

      timerChart: [
        {
          label: 'Theo ngày',
          value: 'd',
        },
        {
          label: 'Theo tháng',
          value: 'm',
        },
      ],

      chartData: {
        chart: {
          type: 'area',
        },

        title: {
          text: 'Báo cáo thống kê',
          align: 'left'
        },


        yAxis: {
            title: {
                text: ''
            },
            gridLineColor: "#BDD6EE",
        },
        xAxis: {
          categories: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
        },

        plotOptions: {
            area: {
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 2,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            }
        },


        series: [{
        
              name: '',
              data: [],
          },
        ]

      },

      //
      dataStatic: {},
      filter: {
        time: "y",
        timeInput: "",
        pack_id: "",
      },

      listChart: {},
      dataMonthchart: ['T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12'],
      dataDayChart: ['1','2','3','4','5','6','7','8','9','10','11','12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],

      listPack: [],
      listHistory: [],
      listTotal: [],
    };
  },
  computed: {
    ...mapGetters("account", ["logged", "accountInfo"]),

  },
  mounted() {
    this.fetchDataStatis();
    this.fetchChart();
    this.fetchPack();
    this.getHistory();
  },
  methods: {
    toProfile() {
      this.$router.push({ name: "account-profile" });
    },

    getHistory() {
      this.loading = true
      axios.get("/log-api/statics/history").then((response) => {
        this.loading = false
        switch (response.code) {
          case 200:
            this.listHistory = response.data.history;
            this.listTotal = response.data.logTotal;

            var sum = 0;
            this.listTotal.forEach((item) => {
              sum += item.total
            })

            console.log('sum', sum);

            this.listTotal.map(function (i) {
              i.totalAll = sum;
              return i;
            });
            this.listTotal = JSON.parse(JSON.stringify(this.listTotal));
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },

    fetchPack() {
      this.loading = true
      axios.get("account/get-pack").then((response) => {
        this.loading = false
        switch (response.code) {
          case 200:
            this.listPack = response.data;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },

    // lấy dữ liệu thống kê 
    fetchDataStatis() {
      let filter = {
        time: this.filter.time,
        pack_id: this.filter.pack_id
      }
      this.loading = true
      axios.get("log-api/statics/dashboard", {params: filter}).then((response) => {
        this.loading = false
        switch (response.code) {
          case 200:
            this.dataStatic = response.data.arr_api;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },

    fetchChart() {
      let filter = {
        pack_id: this.filter.pack_id
      }
      this.loading = true
      axios.get("log-api/statics/dashboardChart", {params: filter}).then((response) => {
        this.loading = false
        switch (response.code) {
          case 200:
          this.chartData.series[0].data = response.data.char_request.chart_month;
          this.listChart = response.data;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },

  },
  watch: {
    "filter.timeInput":function() {
      this.filter.time = this.filter.timeInput;
      if(this.filter.timeInput == '') {
        this.filter.time = 'y'
      }
      this.fetchDataStatis();

      if(this.filter.timeInput == 'd') {
        this.chartData.xAxis.categories = this.dataDayChart;
        this.chartData.series[0].data = this.listChart.char_request.chart_day
      } else {
        this.chartData.xAxis.categories = this.dataMonthchart;
        this.chartData.series[0].data = this.listChart.char_request.chart_month
      }
    },

    "filter.pack_id": function() {
      this.fetchDataStatis();
      this.fetchChart();
    }
  },
};
</script>

<style lang="css" scoped>
.verify_status_error {
  border-radius: 20px;
  background: var(--s15, #FF4778);
  box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
  height: 44px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 13px;
  padding-left: 50px;
  margin: 16px 0;
}
.box-content {
  background: #fff;
  width: 33%;
  height: 160px;
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(13, 10, 44, 0.08);
  padding: 24px 0 24px 24px;
  margin: 5px;
}
.name-box {
  color: var(--s6, #5F616E);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
  /* padding:  0 0 20px 0; */
}
.info-content {
  color: var(--s6, #5F616E);
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 24px;
}
.box-content .number-text-box {
  color: var(--s11, #6049CD);
  font-size: 36px;
  font-weight: 400;
}
.box-content-bottom .box-bt {
  width: 50%;
  background: #fff;
  border-radius: 20px;
  /* height: 150px; */
  padding: 24px;
}
</style>
<style>
.box-white .highcharts-area {
  fill: #6049CD;
}
.boxChart .highcharts-legend-item {
  display: none;
}
</style>